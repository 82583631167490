<template>
  <div>
        
    <a-button class="add-com-btn" @click="hotAgentName='';visible=true" type="primary">添加业务员</a-button>  
    <div class="ant-table-wrapper">
      <div
        class="ant-table ant-table-fixed-header ant-table-scroll-position-left ant-table-layout-fixed ant-table-default"
      >
        <div
          class="ant-table-body"
          style="overflowY:scroll;margin-right:-17px"
        >
          <table class="have-max-width">
            <thead class="ant-table-thead">
              <tr>
                <th>序号</th>
                <th>业务员姓名</th>
                <th >操作</th>
              </tr>
            </thead>
            <tbody class="ant-table-tbody">
              <tr v-for="(item,index) in carList" :key="item.id">
                <td>{{ index+1 }}</td>
                <td>
                  {{item.hotAgentName}}
                </td>
                <td >
                  <a-button type="link" @click="editItem(item)">编辑</a-button>
                  <span class="delete-btn" @click="remove(item)">删除</span>
                </td>
              </tr>
              <tr v-if="!carList.length">
                <td colspan="3" class="no-data">暂无数据</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--    添加车辆-->
    <a-modal
      :title="edit ? '编辑业务员' : '添加业务员'"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      @ok="addMethod"
      @cancel="visible = false"
      >
      <div class="addrole">
        <p class="flex">
          <label for="">业务员名:</label>
          <a-input class="flex1" v-model="hotAgentName"></a-input>
        </p>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      edit: false,
      visible: false,
      hotAgentName:'',
      addCar: {
        hotCode:'',
        status: true,
        examSpotId:this.$store.state.curSpotId
      },
      examSpotId:this.$store.state.curSpotId,
      carList: [],
      editId:''
    };
  },
  methods: {
    moment,
    remove(item){
      this.$confirm({
        title:'是否确认删除？',
        content:`删除角色之后，对应的用户也会被删除！`,
        onOk:()=>{
          this.$post(this.$url.删除业务员, {
            id:item.id,
            examSpotId:this.examSpotId
          }).then(data => {
            this.$tips('删除成功','业务员删除成功！','success')
            this.getList();
          }).catch(() => {
            this.$tips('删除失败','请重新操作！','error')
          });
        }
      })
    },
    choiceExam(){
      this.examList.forEach(item=>{
        if(this.addCar.examSpotId == item.id){
          this.addCar.subjectId = item.subjectId
        }
      })
    },
    addMethod() {
      if (this.edit) {
        this.editCarMethod();
        return;
      }
      this.$post(this.$url.添加业务员, {
        hotAgentName:this.hotAgentName,
        examSpotId:this.examSpotId,
      }).then(() => {
        this.$tips('操作成功','业务员添加成功！','success')
        this.visible = false
        this.getList();
      });
    },
    editItem(item){
      this.visible = true;
      this.edit = true
      this.editId = item.id
      this.hotAgentName = item.hotAgentName
    },
    editCarMethod() {
      this.$post(this.$url.编辑业务员, {
        examSpotId:this.examSpotId,
        hotAgentName:this.hotAgentName,
        id:this.editId
      }).then(() => {
        this.visible = false
        this.$tips('操作成功','业务员编辑成功！','success')
        this.getList();
        this.edit = false;
      });
    },
    getList() {
      this.$post(this.$url.业务员列表, {
        examSpotId:this.examSpotId
      }).then(data => {
        this.carList = data.data.list;
        this.total = data.data.total
      });
    },
  },
  mounted() {
    this.getList();
  }
};
</script>
<style scoped>
.select-self{
  width:300px;
}
.flex {
  line-height: 25px;
}
p.flex {
  margin-bottom: 7px;
}
.flex .flex1 {
  margin-left: 5px;
}

.addrole label {
  display: inline-block;
  width: 68px;
  text-align: right;
  margin-right: 10px;
  box-sizing: border-box;
  line-height: 30px;
}
.ant-input {
  padding: 4px 0px 4px 10px;
  box-sizing: border-box;
}
</style>
